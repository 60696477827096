import { ActionsAbility } from 'shared/types/ability'
import { CACHE, KainosMessageByModule } from 'shared/utils/constants'
import layouts from 'shared/utils/layout'
import { createRouter, createWebHistory } from 'vue-router'

import Forbidden from 'ui/src/Forbidden.vue'
import NotFound from 'ui/src/NotFound.vue'

import { hasPermission } from '@/core/ability'
import { setI18nMessage } from '@/locales'
import { useAuthStore } from '@/stores/auth'
import { useMainStore } from '@/stores/main'

import EnterNewPasswordPopup from '../views/pages/authentication/EnterNewPasswordPopup.vue'
import Login from '../views/pages/authentication/LoginView.vue'
// Main app
import MainPage from '../views/pages/MainPage.vue'
import boardRoutes from './hnvw-mf-board'
import ewmsHistoryRouter from './hnvw-mf-ewms-history'
import ewmsMovementRouter from './hnvw-mf-ewms-movement'
import ewmsReceiptRouter from './hnvw-mf-ewms-receipt'
import ewmsReleaseRouter from './hnvw-mf-ewms-release'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    // eWMS
    ...ewmsReceiptRouter,
    ...ewmsMovementRouter,
    ...ewmsReleaseRouter,
    ...ewmsHistoryRouter,
    ...boardRoutes,

    // Main app
    {
      path: '/',
      alias: '/dashboard',
      name: 'Dashboard',
      component: MainPage,
      meta: {
        auth: true,
        layout: layouts.navLeft,
        title: 'dashboard',
      },
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        layout: layouts.contenOnly,
      },
    },
    {
      path: '/login/:catchAll(.*)',
      name: 'LoginCatchAll',
      component: Login,
      meta: {
        layout: layouts.contenOnly,
      },
    },
    {
      path: '/reset-password',
      name: 'ResetPassword',
      component: EnterNewPasswordPopup,
      props: (route) => ({
        token: route.query.token,
        visible: true,
      }),
    },
    {
      path: '/logout',
      redirect: () => {
        useAuthStore().setLogout()
        return '/login'
      },
    },
    {
      path: '/',
      name: 'Demo',
      component: () => import('../views/pages/Demo.vue'),
    },
    {
      path: '/ewms/main/contact',
      name: 'eWMSContact',
      component: () => import('../views/pages/e-wms/Contact.vue'),
      meta: {
        layout: layouts.navLeft,
        auth: false,
        permission: false,
        title: 'contactUs',
        isSpecial: true,
        breadCrumbs: [{ text: 'main' }, { text: 'Location', isActive: true }],
      },
    },
    {
      path: '/ewms/main/location',
      name: 'eWMSLocation',
      component: () => import('../views/pages/e-wms/Location.vue'),
      meta: {
        layout: layouts.navLeft,
        auth: false,
        permission: false,
        title: 'location',
        isSpecial: true,
        breadCrumbs: [{ text: 'main' }, { text: 'Location', isActive: true }],
      },
    },
    {
      path: '/ebidpro',
      name: 'Ebidpro',
      component: () => import('../views/pages/Ebidpro.vue'),
    },
    {
      path: '/ebidpro/list',
      name: 'EbidproList',
      component: () => import('../views/pages/EbidproList.vue'),
    },
    {
      path: '/ebidpro/list/newEbid',
      name: 'EbidproListNewEbid',
      component: () => import('../views/pages/EbidproNewEbid.vue'),
    },
    {
      path: '/ebidpro/log',
      name: 'EbidproLog',
      component: () => import('../views/pages/EbidproLog.vue'),
    },
    {
      path: '/ebidfeet',
      name: 'Ebidfeet',
      component: () => import('../views/pages/Ebidfeet.vue'),
    },
    {
      path: '/ebidfeet/list',
      name: 'EbidfeetList',
      component: () => import('../views/pages/EbidfeetList.vue'),
    },
    {
      path: '/403',
      name: 'Forbidden',
      component: Forbidden,
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: NotFound,
      meta: {
        layout: layouts.contenOnly,
        title: '',
      },
    },

    //  ------------------------ /* About-Us */ ------------------------
    {
      path: '/hmm-introduction/directions',
      name: 'HMM 찾아오시는길',
      redirect: () => {
        return '/ewms/main/location'
      },
    },
    {
      path: '/hos-introduction/directions',
      name: 'HOS 찾아오시는길',
      redirect: () => {
        return '/ewms/main/location'
      },
    },
  ],
})

// init channel
const channel = new BroadcastChannel('session-sync')
// init check flag
let isActiveSession = false
// Listen request
channel.onmessage = (event) => {
  const { type, data } = event.data
  if (type === 'REQUEST_SYNC') {
    channel.postMessage({
      type: 'SYNC_SESSION',
      data: sessionStorage.getItem('session-key'),
    })
  }
  if (type === 'SYNC_SESSION') {
    if (data) {
      sessionStorage.setItem('session-key', data)
      isActiveSession = true
    }
  }
}

const handleLogout = () => {
  const auth = useAuthStore()
  auth.setLogout()
  window.location.href = '/login'
}

router.beforeEach(async (to) => {
  const isStaySignedIn = parseInt(localStorage.getItem(CACHE.STAY_SIGNED_IN))
  const isSessionExist = sessionStorage.getItem('session-key')
  // send request
  channel.postMessage({ type: 'REQUEST_SYNC' })
  // check login
  const auth = useAuthStore()
  if (to.meta.auth) {
    if (auth.isLogged) {
      if (to.name === 'Login') {
        window.location.href = '/'
        return false
      } else if (!isStaySignedIn && !isSessionExist) {
        setTimeout(() => {
          if (!isActiveSession) {
            handleLogout()
          }
        }, 250)
      }
    } else if (to.name !== 'Login' && to.name !== 'ResetPassword') {
      window.location.href = '/login'
      return false
    }
  } else if (
    auth.isLogged &&
    (to.name === 'Login' || to.name === 'Register' || to.name === 'ResetPassword')
  ) {
    window.location.href = '/'
    return false
  } else if (auth.isLogged && !isStaySignedIn && !isSessionExist) {
    setTimeout(() => {
      if (!isActiveSession) {
        handleLogout()
      }
    }, 250)
  }

  if (to.meta.permission && !hasPermission(ActionsAbility.VISIBLE, to.name.toString())) {
    window.location.href = '/403'
    return false
  }
})

router.afterEach((to) => {
  if (to.meta.layout) {
    useMainStore().setLayout(to.meta.layout)
  }
  setI18nMessage(to.meta.locales ? (to.meta.locales as KainosMessageByModule[]) : [])
  setTimeout(() => {
    useMainStore().setSplashScreen(false)
  }, 250)
})

export default router

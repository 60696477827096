import { ref } from 'vue'

import ContactUsIcon from '@/assets/icons/contact-us-icon.svg?component'
import GoodsReceiptIcon from '@/assets/icons/goods-receipt-icon.svg?component'
import GoodsReleaseIcon from '@/assets/icons/goods-release-icon.svg?component'
import HistoryInfoIcon from '@/assets/icons/history-info-icon.svg?component'
import MovementOrderIcon from '@/assets/icons/movement-order-icon.svg?component'
import SupportIcon from '@/assets/icons/support-icon.svg?component'

import { Menu } from './type'

export const menuItemSample = ref<Menu[]>([
  {
    key: 'ewms-goods-receipt',
    number: 100,
    path: '',
    name: 'Goods Receipt',
    koreanName: 'Goods Receipt',
    level: 'LEVEL1',
    parentNumber: null,
    icon: GoodsReceiptIcon,
    children: [
      {
        key: 'ewms-goods-receipt-supply',
        number: 101,
        path: '/ewms/goods-receipt/supply-receipt',
        name: 'Supply',
        koreanName: 'Supply',
        level: 'LEVEL2',
        parentNumber: 100,
        children: [],
        isVisible: true,
        isPrintable: false,
        isRetrieval: true,
        isEditable: true,
        isDeletable: true,
        isAdmin: true,
      },
      {
        key: 'ewms-goods-receipt-vessel',
        number: 102,
        path: '/ewms/goods-receipt/vessel-movement-receipt',
        name: 'Vessel Movement',
        koreanName: 'Vessel Movement',
        level: 'LEVEL2',
        parentNumber: 100,
        children: [],
        isVisible: true,
        isPrintable: false,
        isRetrieval: true,
        isEditable: true,
        isDeletable: true,
        isAdmin: true,
      },
    ],
    isVisible: true,
    isPrintable: false,
    isRetrieval: true,
    isEditable: true,
    isDeletable: true,
    isAdmin: true,
  },
  {
    key: 'ewms-movement-order',
    number: 200,
    path: null,
    name: 'Movement Order',
    koreanName: 'Movement Order',
    level: 'LEVEL1',
    parentNumber: null,
    icon: MovementOrderIcon,
    children: [
      {
        key: 'ewms-movement-order-supply',
        number: 201,
        path: '/ewms/movement-order/movement-plan-supply',
        name: 'Order',
        koreanName: 'Order',
        level: 'LEVEL2',
        parentNumber: 200,
        children: [],
        isVisible: true,
        isPrintable: false,
        isRetrieval: true,
        isEditable: false,
        isDeletable: false,
        isAdmin: false,
      },
      {
        key: 'ewms-movement-order-history',
        number: 202,
        path: '/ewms/movement-order/movement-history',
        name: 'Movement History',
        koreanName: 'Movement History',
        level: 'LEVEL2',
        parentNumber: 200,
        children: [],
        isVisible: true,
        isPrintable: false,
        isRetrieval: true,
        isEditable: false,
        isDeletable: false,
        isAdmin: false,
      },
    ],
    isVisible: true,
    isPrintable: false,
    isRetrieval: false,
    isEditable: false,
    isDeletable: false,
    isAdmin: false,
  },
  {
    key: 'ewms-goods-release',
    number: 300,
    path: '',
    name: 'Goods Release',
    koreanName: 'Goods Release',
    level: 'LEVEL1',
    parentNumber: null,
    icon: GoodsReleaseIcon,
    children: [
      {
        key: 'ewms-goods-release-supply',
        number: 301,
        path: '/ewms/goods-release/supply-release',
        name: 'Supply',
        koreanName: 'Supply',
        level: 'LEVEL2',
        parentNumber: 300,
        children: [],
        isVisible: true,
        isPrintable: false,
        isRetrieval: true,
        isEditable: true,
        isDeletable: true,
        isAdmin: true,
      },
      {
        key: 'ewms-goods-release-vessel',
        number: 302,
        path: '/ewms/goods-release/vessel-movement-release',
        name: 'Vessel Movement',
        koreanName: 'Vessel Movement',
        level: 'LEVEL2',
        parentNumber: 300,
        children: [],
        isVisible: true,
        isPrintable: false,
        isRetrieval: true,
        isEditable: true,
        isDeletable: true,
        isAdmin: true,
      },
    ],
    isVisible: true,
    isPrintable: false,
    isRetrieval: false,
    isEditable: false,
    isDeletable: false,
    isAdmin: false,
  },
  {
    key: 'ewms-history-info',
    number: 400,
    path: '',
    name: 'History Info',
    koreanName: 'History Info',
    level: 'LEVEL1',
    parentNumber: null,
    icon: HistoryInfoIcon,
    children: [
      {
        key: 'ewms-history-info-history',
        number: 401,
        path: '/ewms/history-info/history',
        name: 'History',
        koreanName: 'History',
        level: 'LEVEL2',
        parentNumber: 400,
        children: [],
        isVisible: true,
        isPrintable: false,
        isRetrieval: true,
        isEditable: true,
        isDeletable: true,
        isAdmin: true,
      },
    ],
    isVisible: true,
    isPrintable: false,
    isRetrieval: true,
    isEditable: true,
    isDeletable: true,
    isAdmin: true,
  },
  // {
  //   key: 'ewms-admin-config',
  //   number: 500,
  //   path: null,
  //   name: 'Admin',
  //   koreanName: 'Admin',
  //   level: 'LEVEL2',
  //   parentNumber: 1973,
  //   children: [],
  //   isVisible: true,
  //   isPrintable: false,
  //   isRetrieval: true,
  //   isEditable: true,
  //   isDeletable: true,
  //   isAdmin: true,
  // },
  {
    key: 'contactUs',
    number: 500,
    name: 'Contact Us',
    koreanName: '',
    level: 'LEVEL1',
    current: false,
    parentNumber: null,
    path: '',
    icon: ContactUsIcon,
    children: [
      {
        key: 'ewms-contact',
        number: 501,
        path: '/ewms/main/contact',
        name: 'Contact Us',
        koreanName: '',
        level: 'LEVEL2',
        parentNumber: 500,
        children: [],
        isVisible: true,
        isPrintable: false,
        isRetrieval: true,
        isEditable: true,
        isDeletable: true,
        isAdmin: true,
      },
      {
        key: 'ewms-location',
        number: 502,
        path: '/ewms/main/location',
        name: 'Location',
        koreanName: '',
        level: 'LEVEL2',
        parentNumber: 500,
        children: [],
        isVisible: true,
        isPrintable: false,
        isRetrieval: true,
        isEditable: true,
        isDeletable: true,
        isAdmin: true,
      },
    ],
    isVisible: true,
    isPrintable: false,
    isRetrieval: true,
    isEditable: true,
    isDeletable: true,
    isAdmin: true,
  },
  {
    key: 'support',
    number: 600,
    name: 'Support',
    koreanName: '',
    level: 'LEVEL1',
    current: false,
    path: '',
    parentNumber: null,
    icon: SupportIcon,
    children: [
      {
        key: 'support-board-news',
        number: 601,
        path: '/support/board/notice',
        name: 'Notice',
        koreanName: '',
        level: 'LEVEL2',
        parentNumber: 600,
        children: [],
        isVisible: true,
        isPrintable: true,
        isRetrieval: true,
        isEditable: false,
        isDeletable: false,
        isAdmin: false,
      },
      {
        key: 'support-board-qa',
        number: 602,
        path: '/support/board/qa',
        name: 'QA',
        koreanName: '',
        level: 'LEVEL2',
        parentNumber: 600,
        children: [],
        isVisible: true,
        isPrintable: true,
        isRetrieval: true,
        isEditable: true,
        isDeletable: true,
        isAdmin: false,
      },
      {
        key: 'support-board-faq',
        number: 603,
        path: '/support/board/faq',
        name: 'FAQ',
        koreanName: '',
        level: 'LEVEL2',
        parentNumber: 600,
        children: [],
        isVisible: true,
        isPrintable: true,
        isRetrieval: true,
        isEditable: false,
        isDeletable: false,
        isAdmin: false,
      },
    ],
  },
  // ##########################################################
  // Please do not delete it. (start)
  // ##########################################################
  // {
  //   key: 'hmm-introduction',
  //   number: 30,
  //   name: 'HMM 소개',
  //   koreanName: 'HMM 소개',
  //   level: 'LEVEL1',
  //   current: false,
  //   icon: {},
  //   path: '',
  //   parentNumber: null,
  //   children: [
  //     {
  //       key: 'hmm-introduction-about-us',
  //       number: 31,
  //       name: '회사소개',
  //       koreanName: '회사소개',
  //       level: 'LEVEL2',
  //       parentNumber: 30,
  //       path: 'https://www.hmm21.com/company/aboutUs.do',
  //       icon: {},
  //       children: [],
  //       isVisible: true,
  //       isPrintable: false,
  //       isRetrieval: true,
  //       isEditable: true,
  //       isDeletable: true,
  //       isAdmin: true,
  //     },
  //     {
  //       key: 'hmm-introduction-ceo-message',
  //       number: 32,
  //       name: 'CEO 인사말',
  //       koreanName: 'CEO 인사말',
  //       level: 'LEVEL2',
  //       parentNumber: 30,
  //       path: 'https://www.hmm21.com/company/ceoGreeting.do',
  //       icon: {},
  //       children: [],
  //       isVisible: true,
  //       isPrintable: false,
  //       isRetrieval: true,
  //       isEditable: true,
  //       isDeletable: true,
  //       isAdmin: true,
  //     },
  //     {
  //       key: 'hmm-introduction-history',
  //       number: 33,
  //       name: '회사연혁',
  //       koreanName: '회사연혁',
  //       level: 'LEVEL2',
  //       parentNumber: 30,
  //       path: 'https://www.hmm21.com/company/history.do',
  //       icon: {},
  //       children: [],
  //       isVisible: true,
  //       isPrintable: false,
  //       isRetrieval: true,
  //       isEditable: true,
  //       isDeletable: true,
  //       isAdmin: true,
  //     },
  //     {
  //       key: 'hmm-introduction-promotional-video',
  //       number: 34,
  //       name: '홍보동영상',
  //       koreanName: '홍보동영상',
  //       level: 'LEVEL2',
  //       parentNumber: 30,
  //       path: 'https://www.youtube.com/watch?v=soUrMKdkVoM',
  //       icon: {},
  //       children: [],
  //       isVisible: true,
  //       isPrintable: false,
  //       isRetrieval: true,
  //       isEditable: true,
  //       isDeletable: true,
  //       isAdmin: true,
  //     },
  //     {
  //       key: 'hmm-introduction-image-gallery',
  //       number: 35,
  //       name: '이미지 갤러리',
  //       koreanName: '이미지 갤러리',
  //       level: 'LEVEL2',
  //       parentNumber: 30,
  //       path: 'https://www.hmm21.com/company/media/gallery.do',
  //       icon: {},
  //       children: [],
  //       isVisible: true,
  //       isPrintable: false,
  //       isRetrieval: true,
  //       isEditable: true,
  //       isDeletable: true,
  //       isAdmin: true,
  //     },
  //     {
  //       key: 'hmm-introduction-directions',
  //       number: 36,
  //       name: '찾아오시는길',
  //       koreanName: '찾아오시는길',
  //       level: 'LEVEL2',
  //       parentNumber: 30,
  //       path: '/hmm-introduction/directions',
  //       icon: {},
  //       children: [],
  //       isVisible: true,
  //       isPrintable: false,
  //       isRetrieval: true,
  //       isEditable: true,
  //       isDeletable: true,
  //       isAdmin: true,
  //     },
  //   ],
  //   isVisible: true,
  //   isPrintable: false,
  //   isRetrieval: true,
  //   isEditable: true,
  //   isDeletable: true,
  //   isAdmin: true,
  // },
  // {
  //   key: 'hmm-introduction-ethics-hotline',
  //   number: 37,
  //   name: '사이버제보실',
  //   koreanName: '사이버제보실',
  //   level: 'LEVEL1',
  //   parentNumber: null,
  //   path: 'https://www.hmm21.com/ethics/informantReg.do?lang=en',
  //   icon: {},
  //   children: [],
  //   isVisible: true,
  //   isPrintable: false,
  //   isRetrieval: true,
  //   isEditable: true,
  //   isDeletable: true,
  //   isAdmin: true,
  // },
  // {
  //   key: 'hos-introduction',
  //   number: 40,
  //   name: 'HOS 소개',
  //   koreanName: 'HOS 소개',
  //   level: 'LEVEL1',
  //   current: false,
  //   icon: {},
  //   path: '',
  //   parentNumber: null,
  //   children: [
  //     {
  //       key: 'hos-introduction-about-us',
  //       number: 41,
  //       name: '회사소개',
  //       koreanName: '회사소개',
  //       level: 'LEVEL2',
  //       parentNumber: 30,
  //       path: 'https://hos21.com/about/company.aspx',
  //       icon: {},
  //       children: [],
  //       isVisible: true,
  //       isPrintable: false,
  //       isRetrieval: true,
  //       isEditable: true,
  //       isDeletable: true,
  //       isAdmin: true,
  //     },
  //     {
  //       key: 'hos-introduction-ceo-message',
  //       number: 42,
  //       name: 'CEO 인사말',
  //       koreanName: 'CEO 인사말',
  //       level: 'LEVEL2',
  //       parentNumber: 40,
  //       path: 'https://hos21.com/about/ceo.aspx',
  //       icon: {},
  //       children: [],
  //       isVisible: true,
  //       isPrintable: false,
  //       isRetrieval: true,
  //       isEditable: true,
  //       isDeletable: true,
  //       isAdmin: true,
  //     },
  //     {
  //       key: 'hos-introduction-history',
  //       number: 43,
  //       name: '회사연혁',
  //       koreanName: '회사연혁',
  //       level: 'LEVEL2',
  //       parentNumber: 40,
  //       path: 'https://hos21.com/about/history.aspx',
  //       icon: {},
  //       children: [],
  //       isVisible: true,
  //       isPrintable: false,
  //       isRetrieval: true,
  //       isEditable: true,
  //       isDeletable: true,
  //       isAdmin: true,
  //     },
  //     {
  //       key: 'hos-introduction-promotional-video',
  //       number: 44,
  //       name: '홍보동영상',
  //       koreanName: '홍보동영상',
  //       level: 'LEVEL2',
  //       parentNumber: 40,
  //       path: 'https://hos21.com/prcenter/pr1.aspx',
  //       icon: {},
  //       children: [],
  //       isVisible: true,
  //       isPrintable: false,
  //       isRetrieval: true,
  //       isEditable: true,
  //       isDeletable: true,
  //       isAdmin: true,
  //     },
  //     {
  //       key: 'hos-introduction-image-gallery',
  //       number: 45,
  //       name: '이미지 갤러리',
  //       koreanName: '이미지 갤러리',
  //       level: 'LEVEL2',
  //       parentNumber: 40,
  //       path: 'https://hos21.com/prcenter/newsList.aspx',
  //       icon: {},
  //       children: [],
  //       isVisible: true,
  //       isPrintable: false,
  //       isRetrieval: true,
  //       isEditable: true,
  //       isDeletable: true,
  //       isAdmin: true,
  //     },
  //     {
  //       key: 'hos-introduction-directions',
  //       number: 46,
  //       name: '찾아오시는길',
  //       koreanName: '찾아오시는길',
  //       level: 'LEVEL2',
  //       parentNumber: 40,
  //       path: '/hos-introduction/directions',
  //       icon: {},
  //       children: [],
  //       isVisible: true,
  //       isPrintable: false,
  //       isRetrieval: true,
  //       isEditable: true,
  //       isDeletable: true,
  //       isAdmin: true,
  //     },
  //   ],
  //   isVisible: true,
  //   isPrintable: false,
  //   isRetrieval: true,
  //   isEditable: true,
  //   isDeletable: true,
  //   isAdmin: true,
  // },
  // ##########################################################
  // Please do not delete it. (End)
  // ##########################################################
])

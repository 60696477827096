<template>
  <div class="w-full h-screen flex flex-col">
    <div class="top-nav">
      <TopNav :is-show-sidebar="isShowSidebar" @toggle-sidebar="isShowSidebar = !isShowSidebar" />
    </div>
    <div class="md:hidden h-[150px] bg-white border-b flex flex-col">
      <UserNav />
      <ActionsNav />
    </div>
    <div class="!flex-1 flex flex-row flex-grow overflow-auto">
      <div v-if="isShowSidebar" class="left-sidebar bg-white overflow-auto shadow-xl z-10">
        <SidebarLeft />
      </div>
      <div
        class="flex-grow overflow-auto bg-white mainScroll transition-all duration-200"
        :class="{ 'pr-[90px]': isShowQuickPanel }"
      >
        <div class="flex flex-col h-full">
          <MainBreadCrumb />
          <div class="flex-1 p-4 bg-white overflow-auto">
            <RouterView class="h-full" />
          </div>
        </div>
      </div>
      <!-- <QuickPanel /> -->
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { RouterView, useRoute } from 'vue-router'

import MainBreadCrumb from '@/components/MainBreadCrumb.vue'
import QuickPanel from '@/components/QuickPanel.vue'
import SidebarLeft from '@/components/Sidebar/SidebarLeft.vue'
import ActionsNav from '@/components/TopNav/ActionsNav.vue'
import TopNav from '@/components/TopNav/TopNav.vue'
import { useMainStore } from '@/stores/main'
import Footer from '@/views/components/Footer.vue'

const route = useRoute()
const mainStore = useMainStore()
const isShowQuickPanel = computed(() => mainStore.layout.quickPanel)

const isShowSidebar = ref(true)

watch(
  () => route.fullPath,
  () => {
    document.querySelector('.mainScroll')?.scrollTo(0, 0)
  }
)
</script>

<style lang="scss" scoped>
.top-nav {
  flex: 0 0 70px;
}
.left-sidebar {
  flex: 0 0 90px;
}
</style>
